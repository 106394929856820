/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Aeroport from '../../static/fonts/Aeroport.woff2'

function SEO({
  description, lang, meta, keywords, title, project
}) {
  const { site, images, avatar } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            social {
              twitter
            }
          }
        }
        avatar: file(absolutePath: { regex: "/avatar.jpg/" }) {
          childImageSharp {
            resize(width: 400, height: 400, quality: 100) {
              src
            }
          }
        }
        images: allFile(filter: {extension: {in: "png"}, name: {eq: "og"}}) {
          edges {
            node {
              relativePath
              childImageSharp {
                resize(width: 1000, height: 1000, quality: 80) {
                  src
                }
              }
            }
          }
        }
      }
    `,
  );
  const metaDescription = description || site.siteMetadata.description;
  const match = typeof project !== 'undefined' ? images.edges.find(({ node }) => `${project}/og.png` === node.relativePath).node : avatar
  const image = site.siteMetadata.siteUrl + match.childImageSharp.resize.src

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.social.twitter,
        },
        {
          name: 'twitter:title',
          content: title === "Home" ? site.siteMetadata.title : title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'preload',
          as: 'font',
          href: Aeroport,
          type: 'font/woff2',
          crossOrigin: 'anonymous'
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
            : [],
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
