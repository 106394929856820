// import React, {useEffect} from "react"
// import { useColorMode, Container } from "theme-ui"
import React from "react"
import { Container } from "theme-ui"
import { css, Global } from "@emotion/core"
import Header from "../components/header"
import Footer from "../components/footer"
import { ParallaxProvider } from 'react-scroll-parallax'


export default ({style, ...props}) => {
  // const [, setColorMode] = useColorMode()

  // useEffect(() => {
  //   const switchMode = (e) => {
  //     const isDarkMode = e.matches;
  //     isDarkMode ? setColorMode("dark") : setColorMode("light");
  //   };
  //   const darkModeMediaQuery = window.matchMedia(
  //     "(prefers-color-scheme: dark)"
  //   );
  //   darkModeMediaQuery.addListener(switchMode);
  //   // cleanup on unmount
  //   return () => darkModeMediaQuery.removeListener(switchMode);
  // }, [setColorMode]);

  return (
    <ParallaxProvider>
      <Global styles={GlobalStyle} />
      <Header/>
      <Container as='main' sx={{pt: 'calc(4.78vw + 84.704px)', pb: 12, minHeight: "calc(100vh - 32px)", ...style}} >
        {props.children}
      </Container>
      <Footer/>
    </ParallaxProvider>
)}

const GlobalStyle = (theme) => css`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  @media only screen and (min-width : 320px) and (max-width : 820px) {
	  body {-webkit-text-size-adjust: none;}
  }

  ::selection {
    background: ${theme.colors.highlight};
    color: ${theme.colors.background};
  }

  span.incline {
    display: inline-block;
    transform-origin: bottom left;
    &:nth-of-type(1) {
      transform: translateY(-1px) rotate(-1deg)
    }
    &:nth-of-type(2) {
      transform: translateX(0.015625em) translateY(-0.03125em) rotate(-4deg)
    }
    &:nth-of-type(3) {
      transform: translateX(0.046875em)translateY(-0.0625em) rotate(-10deg)
    }
    &:nth-of-type(4) {
      transform: translateX(0.109375em) translateY(-0.15625em) rotate(-17deg)
    }
  }
`;
