/** @jsx jsx */
import { jsx, Flex, Container } from 'theme-ui'
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { isSafari } from "react-device-detect";

export default () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      avatar: file(absolutePath: { regex: "/avatar.jpg/" }) {
        childImageSharp {
          fixed(width: 44, height: 44, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata

  return (
    <Container role="banner" as='header' id='header' sx={{
      position: 'fixed',
      width: '100%',
      top: 0,
      zIndex: 2,
      pointerEvents: 'none',
    }}>
      <Flex sx={{
        variant: 'layout.header' }} >
        <Link to={'/'} sx={{display: "flex"}}>
          <Avatar {...data} alt={author}/>
        </Link>
        <Flex>
          {/* <Link sx={{variant: 'links.nav'}} to='/projects/'>Projects</Link> */}
          <Link sx={{variant: 'links.nav', ml: 2, backdropFilter: 'blur(10px) saturate(4.8)'}} to='/about/'>About</Link>
        </Flex>
      </Flex>
    </Container>
  )
}

const Avatar = ({avatar, ...props}) => <Image
  {...props}
  fixed={avatar.childImageSharp.fixed}
  sx={{borderRadius: '50%', boxShadow: 1,}}
/>
